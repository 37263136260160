//==============================================================================
// GRID SYSTEM MIXINS
//==============================================================================

//--------------------------------------
// Flexbox Grid
//  -> Based on http://flexboxgrid.com/
//--------------------------------------

@mixin fg-container($fluid: false) {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: auto;
  transition: all linear 250ms;

  @if $fluid == true {
    padding-left: $l-gutter;
    padding-right: $l-gutter;
  } @else {
    @include mq($bp-sm-min) {
      max-width: $cont-sm;
    }
    @include mq($bp-md-min) {
      max-width: $cont-md;
    }
    @include mq($bp-lg-min) {
      max-width: $cont-lg;
    }
    @include mq($bp-xl-min) {
      max-width: $cont-xl;
    }
  }
}

@mixin fg-row($reverse: false) {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: $l-gutter-neg;
  margin-right: $l-gutter-neg;

  @if $reverse == true {
    flex-direction: row-reverse;
  }
}

@mixin fg-col($reverse: false) {
  flex: 0 0 auto;
  padding-left: $l-gutter-half;
  padding-right: $l-gutter-half;

  @if $reverse == true {
    flex-direction: column-reverse;
  }
}

@mixin fg-bp-col($cols: 1, $reverse: false) {
  @include fg-col($reverse);
  flex-grow: 1;
  flex-basis: percentage(($cols / $grid-cols));
  max-width: percentage(($cols / $grid-cols));
}

@mixin fg-bp-col-offset($cols: 1) {
  margin-left: percentage(($cols / $grid-cols));
}


@mixin fg-xxs-col($cols, $reverse: false) {
  @include fg-bp-col($cols, $reverse);
}

@mixin fg-xxs-col-offset($cols) {
  @include fg-bp-col-offset($cols);
}


@mixin fg-xs-col($cols, $reverse: false, $bp: $bp-xs-min) {
  @include mq($bp) {
    @include fg-bp-col($cols, $reverse);
  }
}
@mixin fg-xs-col-offset($cols, $bp: $bp-xs-min) {
  @include mq($bp) {
    @include fg-bp-col-offset($cols);
  }
}


@mixin fg-sm-col($cols, $reverse: false, $bp: $bp-sm-min) {
  @include mq($bp) {
    @include fg-bp-col($cols, $reverse);
  }
}
@mixin fg-sm-col-offset($cols, $bp: $bp-sm-min) {
  @include mq($bp) {
    @include fg-bp-col-offset($cols);
  }
}


@mixin fg-md-col($cols, $reverse: false, $bp: $bp-md-min) {
  @include mq($bp) {
    @include fg-bp-col($cols, $reverse);
  }
}
@mixin fg-md-col-offset($cols, $bp: $bp-md-min) {
  @include mq($bp) {
    @include fg-bp-col-offset($cols);
  }
}


@mixin fg-lg-col($cols, $reverse: false, $bp: $bp-lg-min) {
  @include mq($bp) {
    @include fg-bp-col($cols, $reverse);
  }
}
@mixin fg-lg-col-offset($cols, $bp: $bp-lg-min) {
  @include mq($bp) {
    @include fg-bp-col-offset($cols);
  }
}


@mixin fg-xl-col($cols, $reverse: false) {
  @include mq($bp-xl-min) {
    @include fg-bp-col($cols, $reverse);
  }
}
@mixin fg-xl-col-offset($cols) {
  @include mq($bp-xl-min) {
    @include fg-bp-col-offset($cols);
  }
}
