@import "./_sass-essentials/essentials.scss";

.pt--dual-cta-buttons {
  & > .paragraph-inner {
    @include fg-row();

    > .col {
      @include fg-xxs-col(6);

      > .dual-cta-btn {
        height: 100%;
      }
    }
  }
}
