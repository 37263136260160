.pt--dual-cta-buttons > .paragraph-inner {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.pt--dual-cta-buttons > .paragraph-inner > .col {
  flex: 0 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  flex-grow: 1;
  flex-basis: 50%;
  max-width: 50%;
}

.pt--dual-cta-buttons > .paragraph-inner > .col > .dual-cta-btn {
  height: 100%;
}
